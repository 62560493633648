import React from 'react';
import Navigation from '../Navigation';
import { Link } from 'react-router-dom';

// Material UI
import Container from '@material-ui/core/Container';

const About = (props) => {
    return (
        <Container>
            <Navigation />
            <h1 className="text-5xl py-8">Welcome to TorahStream </h1>
            <div className="flex mb-10 flex-col md:flex-row">
                <div className="w-2/3 mr-20 rounded-md">
                    <img
                        alt="Rav Viener"
                        id="rav-viener-picture"
                        src="assets/rabbi_viener.jpg"
                        className="w-96"
                    />
                </div>
                <div className="text-xl">
                    <div>
                        Rabbi Yosef Viener, author of{' '}
                        <Link to="http://www.artscroll.com/Books/cqhh.html">
                            {' '}
                            Contemporary Questions in Halachah and Hashkafah
                        </Link>
                        , has inspired and enlightened listeners worldwide with
                        his shiurim on Halachah and Hashkafah - crystallizing
                        the Torah's timeless wisdom and applying to our daily
                        lives. TorahStream.org is proud to bring you{' '}
                        <Link to="/shiurim">these fascinating lectures</Link> so
                        you too can benefit from his Torah knowledge and
                        practical life guidance at the time and place of your
                        convenience.
                    </div>
                    <div className="pt-6">
                        <a
                            href="/shiurim"
                            className="bg-blue-400 p-3 my-5 rounded-md"
                        >
                            Go To Shiurim
                        </a>
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-4 mb-10">
                <div className="flex flex-col">
                    <img
                        className="lg:w-2/3 w-full p-5 mx-auto"
                        src="https://cdn.shopify.com/s/files/1/0093/3625/8657/products/138-8_6be6977d-80d2-4360-b721-bac44a64643a_500x.jpg?v=1635954806"
                    />
                    <div className="mx-auto">
                        <a
                            href="https://www.artscroll.com/Books/9781422611388.html"
                            className="bg-blue-400 p-3 my-5 rounded-md"
                        >
                            Buy Now
                        </a>
                    </div>
                </div>
                <div className="flex flex-col">
                    <img
                        className="lg:w-2/3 w-full p-5 mx-auto"
                        src="assets/2023-06-19_21-59.png"
                    />
                    <div className="mx-auto">
                        Available in many seforim stores. (Also at ZBerman) If
                        you cannot find it near you, you can reach out to:
                        <br />
                        <span
                            className="email mr-5"
                            data-email="seforim@torahstream.org"
                        ></span>{' '}
                        <br />
                        <span
                            className="email"
                            data-email="chukoseyhem@torahstream.org"
                        ></span>
                    </div>
                </div>
                <div className="flex flex-col p-2">
                    <img
                        className="lg:w-2/3 w-full p-5 mx-auto"
                        src="https://judaicaplaza.com/cdn/shop/products/6197067_600x.jpg?v=1689778722"
                    />
                    <div className="mx-auto">
                        Torah 2 Go: R' Yosef Viener Nisyonos, Bitachon, and
                        Tefilah Series (USB)
                        <div className="pt-5 text-center">
                            <a
                                href="https://judaicaplaza.com/products/6197067?_pos=3&_sid=60577d0f7&_ss=r"
                                className="bg-blue-400 p-3 my-5 rounded-md"
                            >
                                Buy Now
                            </a>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col p-2">
                    <img
                        className="lg:w-2/3 w-full p-5 mx-auto"
                        src="https://judaicaplaza.com/cdn/shop/products/6197066_500x.jpg?v=1689778719"
                    />
                    <div className="mx-auto">
                        Torah 2 Go: R' Yosef Viener Inyunei Mishpacha Shiurim
                        (USB)
                        <div className="pt-5 text-center">
                            <a
                                href="https://judaicaplaza.com/products/6197066"
                                className="bg-blue-400 p-3 my-5 rounded-md"
                            >
                                Buy Now
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default About;
